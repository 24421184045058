import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './case-studies-styles.scss'
import {
  CaseStudies,
  BannerCTA,
  Seo,
  Spinner,
  Gallery,
  KeyServices,
  TitleTextBlock,
} from '../../components'
import {
  Description,
  CardHeader,
} from './components'

const CaseStudyView = ({
  data,
  loading,
}) => {
  const { t } = useTranslation()
  if (loading) {
    return <Spinner loading={loading} />
  }
  return (
    <React.Fragment>
      <div className="caseStudy__container">
        <Seo title="Season Group: Services" />
        <div>
          {_.map(_.get(data, 'acf.rows'), (layouts, idx) => {
            let layout
            switch (true) {
              case layouts.acf_fc_layout === 'hero_card':
                layout = <div key={idx} className='caseStudy__content head'>
                  <TitleTextBlock
                    widthImage={false}
                    layout={layouts}
                    component={CardHeader}
                    text={t('tagline.case-studies')}
                  >
                    {layouts.title}
                  </TitleTextBlock>
                </div>
                break

              case layouts.acf_fc_layout === 'two_third_copy':
                layout = <div key={idx} className='caseStudy__content description'>
                  <Description data={layouts} t={t} />
                </div>
                break

              case layouts.acf_fc_layout === 'cards':
                layout = <div key={idx} className='caseStudy__content keyServices'>
                  <KeyServices data={layouts} t={t} />
                </div>
                break

              case layouts.acf_fc_layout === 'gallery' && layouts.hide_section === false:
                layout = <div key={idx} className='caseStudy__content gallery'>
                  <Gallery data={layouts} t={t} />
                </div>
                break

              case layouts.acf_fc_layout === 'case_studies_list':
                layout = <div key={idx} className='caseStudy__content more'>
                  <CaseStudies data={layouts} t={t} />
                </div>
                break

              default:
                break
            }
            return layout
          })}
        </div>

      </div>
      <BannerCTA />

    </React.Fragment>
  )
}

export default CaseStudyView
