import _ from 'lodash'
import React, { useRef } from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import YouTube from 'react-youtube'

const CardHeader = ({ data }) => {
  const breakpoints = useBreakpoint()
  const hasImage = data.image.url
  const idVideo = _.last(_.split(_.get(data, 'video_link'), '='))
  const opts = {
    height: breakpoints.xs ? '236' : '555',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      mute: 1,
    },
    allow: 'autoplay',
  }

  // const onPlayerReady = (event) => {
  //   console.log('🚀 ~ file: index.js:22 ~ onPlayerReady ~ event:', event)
  //   // access to player in all event handlers via event.target
  //   console.log('🚀 ~ file: index.js:25 ~ onPlayerReady ~ refVideo.current:', refVideo.current)
  // }

  return (
    <div className="cardHeader__container row">
      <div className="cardHeader__image col-12 col-md-8">
        {
          hasImage ? (
            <img src={data.image.url} alt={data.image.alt} />
          ) : (
            <YouTube
              videoId={idVideo}
              opts={opts}
              // onReady={onPlayerReady}
            />
          )
        }
      </div>
      <div className="cardHeader__content col-12 col-md-4">
        <div dangerouslySetInnerHTML={{ __html: data.copy }} />

      </div>
    </div>

  )
}

export default CardHeader
